import { useState, useRef, useEffect } from "react";

import iconcable from "../assets/images/icon.png";

function HomePage() {
  return (
    <div className="page-content">
      <div className="form-v4-content">
        <div className="form-left  rounded-lg text-center p-4 d-flex flex-column align-items-center justify-content-center">
          <div
            className="d-flex bg-white rounded-circle p-4 mb-4"
            style={{ height: "200px", width: "200px" }}
          >
            <img src={iconcable} alt="Cablestar logo" className="img-fluid" />
          </div>
          <h1 className="font-weight-bold">Cablestar</h1>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
