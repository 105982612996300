import moment from "moment";
import { convertToRupiah } from "../../tools/numerictools";
import BtnCopy from "../BtnCopy";
import { newBankList } from "../../tools/BankVariable";

export default function GeneratedVaBank({ generatedVA, presets }) {
  const getBank = (code) => {
    let bankdetail = {};

    if (code === "BCA") {
      bankdetail = {
        code: "BCA",
        name: "Bank BCA",
        logo: "https://api.cablestar.net.id/files/image/L59y8p_G8ORKx1vBVcJus.png",
        switch_code: "va-bca",
      };
      return bankdetail;
    }

    for (let index = 0; index < newBankList.length; index++) {
      if (newBankList[index].code === code) {
        bankdetail = { ...newBankList[index] };
        break;
      }
    }

    return bankdetail;
  };

  let paymentFee =
    presets[getBank(generatedVA.payment_response?.bankCd)?.switch_code]?.fee ||
    0;
  let totalAmount = generatedVA.final_amount + paymentFee;

  return (
    <div className="box-center">
      <div className="alert-payment">
        <span className="note-payment">Segera Lakukan Pembayaran Sebelum:</span>
        <span className="payment-date">
          {moment(generatedVA.payment_response?.vacctValidDt).format(
            "DD MMMM YYYY"
          )}{" "}
          -{" "}
          {moment(generatedVA.payment_response?.vacctValidTm, "HHmmss").format(
            "HH:mm:ss"
          )}{" "}
          WIB
        </span>
      </div>
      <div className="payment-account-info">
        <span className="title">Transfer ke Virtual Account:</span>
        <div className="bank-info">
          <img
            src={`${getBank(generatedVA.payment_response?.bankCd).logo}`}
            alt={`Logo ${getBank(generatedVA.payment_response?.bankCd).name}`}
          />
          <div className="virtul-acc-info">
            <span className="number">
              {generatedVA.payment_response?.vacctNo}
            </span>
            <span className="bank-name">
              {getBank(generatedVA.payment_response?.bankCd).name}
            </span>
          </div>
          <BtnCopy value={generatedVA.payment_response?.vacctNo} />
        </div>
      </div>
      <div className="py-2">
        <span className="title">Jumlah Tagihan</span>
        <div className="amount-info">
          <span className="font-weight-bold">
            Rp{convertToRupiah(generatedVA.final_amount)}
          </span>
        </div>
      </div>
      <div className="py-2">
        <span className="title">Biaya Layanan</span>
        <div className="amount-info">
          <span className="font-weight-bold">
            Rp{" "}
            {convertToRupiah(
              presets[
                getBank(generatedVA.payment_response?.bankCd)?.switch_code
              ]?.fee
            )}
          </span>
        </div>
      </div>
      <div className="payment-account-info pt-0">
        <span className="title">Jumlah yang harus dibayar</span>
        <div className="amount-info">
          <span className="number text-lg">
            Rp{convertToRupiah(totalAmount)}
          </span>
          <BtnCopy value={totalAmount} />
        </div>
      </div>
      <div className="payment-disclaimer">
        <h6>Catatan Pembayaran:</h6>
        <ol>
          <li>
            Lakukan pembayaran sesegera mungkin agar bisa segera diproses.
          </li>
          <li>
            Tata cara pembayaran Virtual Account terdapat pada link berikut{" "}
            <a
              href="https://viberlink.net/cara-bayar-tagihan"
              target="_blank"
              rel="noreferrer"
            >
              https://viberlink.net/cara-bayar-tagihan
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
}
