import { minimartLogo } from "../../tools/BankVariable";
import { convertToRupiah } from "../../tools/numerictools";
import BtnCopy from "../BtnCopy";

export default function GeneratedMinimart({
  brand_code,
  brand,
  generated,
  presets,
}) {
  let paymentFee = presets[brand_code]?.fee || 0;
  let totalAmount = generated.amount + paymentFee;

  return (
    <div className="box-center">
      <div className="alert-payment">
        <span className="note-payment">Segera Lakukan Pembayaran Sebelum:</span>
        <span className="payment-date">{generated.valid_datetime} WIB</span>
      </div>
      <div className="payment-account-info">
        <span className="title">Kode Pembayaran {brand}</span>
        <div className="bank-info">
          <img src={minimartLogo[brand_code]} alt={`Logo ${brand_code}`} />
          <div className="virtul-acc-info">
            <span className="number">{generated.payment_code}</span>
            <span className="bank-name">
              {brand_code === "alfamart" && (
                <>
                  Merchant ID: <b>PlasaMall</b>
                </>
              )}
              {brand_code === "indomaret" && (
                <>
                  Merchant ID: <b>WinSolution</b>
                </>
              )}
            </span>
          </div>
          <BtnCopy value={generated.payment_code} />
        </div>
      </div>
      <div className="py-2">
        <span className="title">Jumlah Tagihan</span>
        <div className="amount-info">
          <span className="font-weight-bold">
            Rp {convertToRupiah(generated.amount || 0)}
          </span>
        </div>
      </div>
      <div className="py-2">
        <span className="title">Biaya Layanan</span>
        <div className="amount-info">
          <span className="font-weight-bold">
            Rp {convertToRupiah(paymentFee)}
          </span>
        </div>
      </div>
      <div className="payment-account-info pt-0">
        <span className="title">Jumlah yang harus dibayar</span>
        <div className="amount-info">
          <span className="number">Rp{convertToRupiah(totalAmount)}</span>
          <BtnCopy value={totalAmount} />
        </div>
      </div>
      <div className="payment-disclaimer">
        <h6>Catatan Pembayaran:</h6>
        <ol>
          {brand_code === "indomaret" && (
            <>
              <li>
                Tunjukkan nomor tagihan <b>{generated.payment_code}</b> dengan
                menyebutkan Merchant ID <b>WinSolution</b> kepada kasir gerai
                Indomaret
              </li>
            </>
          )}
          {brand_code === "alfamart" && (
            <>
              <li>
                Tunjukkan nomor tagihan <b>{generated.payment_code}</b> dengan
                menyebutkan Merchant ID <b>PlasaMall</b> kepada kasir gerai
                Alfamart
              </li>
            </>
          )}
          <li>
            Lakukan pembayaran sesegera mungkin agar bisa segera diproses.
          </li>
        </ol>
      </div>
    </div>
  );
}
