export const BANK_LIST = [
  {
    code: "BMRI",
    name: "Bank Mandiri",
    logo: "logo-mandiri.png",
  },
  {
    code: "IBBK",
    name: "Bank Maybank Indonesia",
    logo: "logo-maybank.png",
  },
  {
    code: "BBBA",
    name: "Bank Permata",
    logo: "logo-permata.png",
  },
  {
    code: "BBBB",
    name: "Bank Permata Syariah",
    logo: "logo-permata-syariah.png",
  },
  {
    code: "BNIN",
    name: "Bank Negara Indonesia",
    logo: "logo-bni.png",
  },
  {
    code: "HNBN",
    name: "Bank KEB Hana Indonesia",
    logo: "logo-keb-hana.png",
  },
  {
    code: "BRIN",
    name: "Bank Rakyat Indonesia",
    logo: "logo-bri.png",
  },
  {
    code: "BNIA",
    name: "Bank CIMB Niaga",
    logo: "logo-cimb.png",
  },
  {
    code: "BDIN",
    name: "Bank Danamon Indonesia",
    logo: "logo-danamon.png",
  },
  {
    code: "PDJB",
    name: "Bank BJB",
    logo: "logo-bjb.png",
  },
];

export const newBankList = [
  {
    code: "BRI",
    name: "Bank Rakyat Indonesia",
    logo: "https://api.cablestar.net.id/files/image/59lz0YEvB1JfzmuVVgjDP.png",
    switch_code: "va-bri",
  },
  {
    code: "BNI",
    name: "Bank Negara Indonesia",
    logo: "https://api.cablestar.net.id/files/image/dCpBZbZSd8DN1iidpL02S.png",
    switch_code: "va-bni",
  },
  {
    code: "MANDIRI",
    name: "Bank Mandiri",
    logo: "https://api.cablestar.net.id/files/image/L-ECrSACKBap99M8BI1bm.png",
    switch_code: "va-mandiri",
  },
  {
    code: "PERMATA",
    name: "Bank Permata",
    logo: "https://api.cablestar.net.id/files/image/M0SJIA_P7X90r2OuDbKuB.png",
    switch_code: "va-permata",
  },
  {
    code: "BSI",
    name: "Bank Syariah Indonesia",
    logo: "https://api.cablestar.net.id/files/image/qIy_jW_L-f3g1AYS27lZy.png",
    switch_code: "va-bsi",
  },
  {
    code: "MUAMALAT",
    name: "Bank Muamalat",
    logo: "https://api.cablestar.net.id/files/image/NB8v8vQ_CccZvmF_4nflF.jpg",
    switch_code: "va-muamalat",
  },
  {
    code: "CIMB",
    name: "Bank CIMB NIAGA",
    logo: "https://api.cablestar.net.id/files/image/DLauhFKLJP2A5ghe8Uf-V.png",
    switch_code: "va-cimb",
  },
  {
    code: "SINARMAS",
    name: "Bank Sinarmas",
    logo: "https://api.cablestar.net.id/files/image/O77NOnFdArxtGo4WudhmG.png",
    switch_code: "va-sinarmas",
  },
  {
    code: "BNC",
    name: "Bank Neo Commerce",
    logo: "https://api.cablestar.net.id/files/image/uoFPIENIDRdEWuDosnpGr.png",
    switch_code: "va-neo-commerce",
  },
];

export const minimartLogo = {
  alfamart: "https://api.cablestar.net.id/files/image/NRP8Q2XCLpnmrqaBcbB51.png",
  indomaret: "https://api.cablestar.net.id/files/image/ZL2TPp_BP1diy92rI9Qos.png"
}

export const QRISLogo = "https://api.cablestar.net.id/files/image/-U0uoqoGSAceA2S1Sll5B.png"