import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import BillingPage from "./pages/Billing";
import BillingPaymentPage from "./pages/BillingPayment";
import CheckRegisPage from "./pages/CheckRegis";
import NotFoundPage from "./pages/NotFound";
import RegisterPage from "./pages/Register";
import HomePage from "./pages/HomePage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/cek-registrasi" component={CheckRegisPage} />
        <Route
          path="/billing/:number/auth/:token"
          component={BillingPaymentPage}
        />
        {/* <Route path="/billing/:number" component={BillingPage} /> */}
        <Route path="/:slug" component={RegisterPage} />
        <Route exact path="/" component={HomePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
